
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.DoYouKnowWrapper {
    width: 100%;
    @include xxl {
        @apply text-4xl;
    }
}
.brief {
    color: #686868;
}
.title {
    @apply text-3xl;
    @include xxl {
        @apply text-4xl;
    }
}
